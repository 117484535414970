<template>
	<div data-route>
		<div data-element="image">
			<image-viewer
				v-if="getImageSrc"
				:src="getImageSrc"
			/>
			<div
				data-element="photo-placeholder"
				v-else
			/>
		</div>
		<div data-element="info">
			<header>
				<h1
					data-element="teacher-name"
					v-if="getNameString"
				>
					{{ getNameString }}
				</h1>
				<div
					data-element="player-ability"
					v-if="getPlayerAbility"
				>
					<icon
						icon="medal"
						size="lg"
						colour="blue"
					/>
					<p data-element="label">
						{{ getPlayerAbility }} player
					</p>
				</div>
				<actions
					:actions="getActions"
					size="sm"
					@actionClick="onActionClick"
				/>
			</header>
			<div
				v-if="getBio"
				data-element="bio"
				v-html="getBio"
			/>
			<div data-element="links">
				<a
					data-element="link"
					v-for="(link, key) in getLinks"
					:key="key"
					:href="link.url"
					target="_blank"
				>
					<icon
						:icon="getLinkIcon(link)"
						size="lg"
						colour="blue"
					/>
					<span>
						{{ link.name }}
					</span>
				</a>
			</div>
			<list-view
				v-if="getList"
				:list="getList"
				@actionClick="onActionClick"
			/>
			<actions
				:actions="getAdminActions"
				size="sm"
				@actionClick="onActionClick"
			/>
		</div>
	</div>
</template>

<script>

	import ImageViewer     from '@/components/uploads/ImageViewer';
	import ListView        from '@/components/ui/views/listView/ListView';
	import Icon            from '@/components/ui/Icon';
	import Actions         from '@/components/ui/Actions';
	import api             from '@/services/api';
	import { languages }   from 'countries-list';
	import { countries }   from 'countries-list';
	import dayjs           from 'dayjs';
	import relativeTime    from 'dayjs/plugin/relativeTime';
	import qs              from 'qs';

	dayjs.extend(relativeTime);

	export default {
		metaInfo () {
			if (!this.getNameString) {
				return false;
			}
			return {
				title: this.getNameString
			};
		},
		components: {
			ImageViewer,
			ListView,
			Icon,
			Actions
		},
		data: () => ({
			teacherProfile: undefined
		}),
		computed: {
			getProfileId () {
				return this.$route.params.profileId;
			},
			getIsArchived () {
				return this.teacherProfile.archived;
			},
			getImageSrc () {
				if (!this.teacherProfile?.media?.[0]?.url) {
					return false;
				}
				return this.teacherProfile.media[0].url;
			},
			getEditRoute () {
				if (!this.getProfileId) {
					return false;
				}
				return `/admin/teachers/${this.getProfileId}`;
			},
			getTeacherCoursesRoute () {
				const filter = qs.stringify({
					teacherName: [
						this.getNameString
					]
				}, {
					arrayFormat: 'comma'
				});
				return `/browse-courses?${filter}`;
			},
			getTeacherProfile () {
				if (!this.getProfileId) {
					return false;
				}
				return this.teacherProfile;
			},
			getPlayerAbility () {
				return this?.getTeacherProfile?.playerAbility;
			},
			getLinks () {
				if (!this.getTeacherProfile?.links?.length) {
					return false;
				}
				return this.getTeacherProfile.links;
			},
			getBio () {
				if (!this.getTeacherProfile) {
					return false;
				}
				return this.getTeacherProfile.bio;
			},
			getNameString () {
				if (!this.getTeacherProfile?.teacherName) {
					return false;
				}
				return this.getTeacherProfile.teacherName;
			},
			getCountryString () {
				if (!this.getTeacherProfile) {
					return false;
				}
				return Object.entries(countries).find((country) => {
					return this.getTeacherProfile.countryCode === country[0];
				})[1].name;
			},
			getLanguageString () {
				if (!this.getTeacherProfile) {
					return false;
				}
				return Object.entries(languages).find((language) => {
					return this.getTeacherProfile.language === language[0];
				})[1].name;
			},
			getList () {
				if (!this.getTeacherProfile) {
					return false;
				}
				return [
					(this.getIsArchived ?
						{
							text: 'Archived teacher'
						} : false
					),
					{
						text: `Country: ${this.getCountryString}`
					},
					{
						text: `Language: ${this.getLanguageString}`
					}
				].filter(Boolean);
			},
			getAdminActions () {
				return {
					primary: [
						(this.$store.getters['auth/getIsAdmin'] ?
							{
								text: 'Edit profile',
								route: this.getEditRoute
							} : false
						)
					].filter(Boolean)
				};
			},
			getActions () {
				return {
					primary: [
						{
							text: 'View courses',
							route: this.getTeacherCoursesRoute
						},
						(!this.teacherProfile?.isSubscribed ?
							{
								text: 'Enable teacher notifications',
								actionId: 'subscribe'
							} : false
						),
						(this.teacherProfile?.isSubscribed ?
							{
								text: 'Disable teacher notifications',
								actionId: 'unsubscribe'
							} : false
						)
					].filter(Boolean)
				};
			},
			getBreadcrumbs () {
				return [
					{
						path: `/teachers/${this.getProfileId}`,
						text: this.getNameString
					}
				].filter(Boolean);
			}
		},
		created: function () {
			this.setTeacher();
		},
		methods: {
			getLinkIcon (link) {
				if (link.type === 'Face to face bridge') {
					return 'user-group';
				}
				if (link.type === 'Bridge cruises') {
					return 'ship';
				}
				return 'plane';
			},
			onActionClick (actionId) {
				if (!this[actionId] ||
					typeof this[actionId] !== 'function') {
					return;
				}
				this[actionId]();
			},
			async setTeacher () {
				const teacherProfile = await api.teacherProfiles.getTeacherProfileById({
					profileId: this.getProfileId
				});
				if (!teacherProfile) {
					this.$store.commit('ui/showError');
					return false;
				}
				this.teacherProfile = teacherProfile;
				this.$store.commit('ui/setBreadcrumbs', {
					breadcrumbs: this.getBreadcrumbs
				});
				this.$store.commit('ui/setLoadingIsHidden');
			},
			async subscribe () {
				const subscription = await api.teacherProfiles.subscribeById({
					profileId: this.getProfileId
				});
				if (!subscription) {
					return false;
				}
				this.$store.commit('ui/showNotification', {
					notification: 'You will be sent email notifications about this teacher.'
				});
				this.teacherProfile.isSubscribed = true;
			},
			async unsubscribe () {
				const subscription = await api.teacherProfiles.unsubscribeById({
					profileId: this.getProfileId
				});
				if (!subscription) {
					return false;
				}
				this.teacherProfile.isSubscribed = false;
				this.$store.commit('ui/showNotification', {
					notification: 'You won’t receive any more email notifications about this teacher.'
				});
			}
		}
	};

</script>

<style lang="scss" scoped>
	[data-element='image'] {
		margin-bottom:rem(32);
		@include mq('sm') {
			@include cols(3);
			@include gut();
			margin-bottom:0;
		}
		[data-component='image-viewer'] {
		}
		[data-element='photo-placeholder'] {
			&:after {
				content:'';
				display:block;
				padding-bottom:100%;
				background-color:$c-brand-grey-lighter-2;
			}
		}
	}
	[data-element='info'] {
		@include mq('sm') {
			@include cols(8);
			@include push(1);
		}
		header {
			margin:0 0 rem(32) 0;
			[data-element='teacher-name'] {
				font-size:rem(32);
				font-weight:bold;
				color:$c-brand-blue;
			}
			[data-element='player-ability'] {
				display:flex;
				[data-component='icon'] {
					flex-shrink:0;
					width:auto;
					height:rem(18);
					margin:auto rem(4) auto 0;
				}
				[data-element='label'] {
					font-size:rem(18);
					font-weight:bold;
					color:$c-brand-blue;
				}
			}
			[data-component='actions'] {
				margin-top:rem(24);
				margin-bottom:0;
				padding-top:0;
				padding-bottom:0;
			}
		}
		::v-deep [data-element='bio'] {
			margin-bottom:rem(28);
			font-size:rem(18);
			@include rich-text-content;
			@include rich-text-suits;
		}
		[data-element='links'] {
			display:flex;
			flex-direction:column;
			align-items:flex-start;
			margin-bottom:rem(28);
			@include mq('sm') {
				flex-direction:row;
			}
			[data-element='link'] {
				display:flex;
				align-items:center;
				justify-content:center;
				margin-right:rem(22);
				&:last-child {
					margin-right:0;
				}
				&:hover {
					text-decoration:underline;
				}
				[data-component='icon'] {
					margin-right:rem(12);
				}
				span {
					font-family:'Montserrat', sans-serif;
					font-weight:bold;
					color:$c-brand-blue;
				}
			}
		}
		[data-element="icons"] {
			justify-content: space-between;
			& > * {
				display: inline-block;
			}
		}
	}
</style>
