import { render, staticRenderFns } from "./_profileId.vue?vue&type=template&id=30ec8f8f&scoped=true&"
import script from "./_profileId.vue?vue&type=script&lang=js&"
export * from "./_profileId.vue?vue&type=script&lang=js&"
import style0 from "./_profileId.vue?vue&type=style&index=0&id=30ec8f8f&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "30ec8f8f",
  null
  
)

export default component.exports